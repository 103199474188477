import Hammer from 'hammerjs'
import { IRootSnackbarGestureHandler } from './root-snackbar-interface'

const CLOSE_FRACTION_THRESHOLD = 3

export default class RootSnackbarGestureHandler implements IRootSnackbarGestureHandler {
  private element: HTMLElement
  private height: number
  private manager: HammerManager | null
  private closeThreshold: number

  constructor(element: HTMLElement, onHide: () => void) {
    if (!element) {
      throw new Error('Element must not be null')
    }

    const manager = new Hammer.Manager(element)

    this.element = element
    this.height = element.clientHeight
    this.manager = manager
    this.closeThreshold = this.height / CLOSE_FRACTION_THRESHOLD

    manager.add(
      new Hammer.Pan({
        threshold: 1,
        direction: Hammer.DIRECTION_DOWN,
      })
    )

    let velocity = 0

    manager.on('pan', e => {
      const deltaY = Math.max(0, e.deltaY)

      if (e.isFinal) {
        // Gesture finished

        if (deltaY >= this.closeThreshold) {
          const pixelsRemaining = this.height - deltaY
          const closeAnimationDuration = Math.min(
            pixelsRemaining / Math.abs(velocity),
            500 /* Default animation duration */
          )

          const closedTranslateY = this.height * (deltaY > 0 ? 1 : -1)

          element.style.transitionDuration = closeAnimationDuration + 'ms'
          this.setTransform(`translateY(${closedTranslateY}px)`)
          element.style.opacity = '0'

          onHide()
        } else {
          this.setTransform('')
          element.style.opacity = '1'
        }
      } else {
        // Remember last velocity before the final pan event.
        if (e.velocity !== 0) {
          velocity = e.velocity
        }

        this.setTransform(`translateY(${deltaY}px)`)
        element.style.opacity = this.calculateOpacity(deltaY)
      }
    })
  }

  private setTransform(transform: string) {
    this.element.style.transform = transform
    this.element.style.webkitTransform = transform
  }

  private calculateOpacity(deltaX: number): string {
    // Solve for linear equation y = mx + b
    const m = -0.8 / this.height
    return `${m * Math.abs(deltaX) + 1}`
  }

  destroy() {
    if (this.manager) {
      this.manager.destroy()
      this.manager = null
    }
  }
}
